import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { useEffect } from "react";
import Logotype from "../../../../components/kit/Logo/Logotype";
import "../index.scss";
import { logoWidth } from "./../../../../constants.json";

const OnlineRecordModal = ({ isOpenModal, onClose, SpecialityId }) => {

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="online-record-modal"
      open={isOpenModal}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpenModal}>
        <div className={"online-record-modal__paper"}>
          <Logotype width={logoWidth} />
          <h2 className="online-record-modal__title">
            {SpecialityId === 41 ||
            SpecialityId === 42
              ? "Запись на ПЦР-тест Covid-19 успешно отправлена!"
              : "Запись на приём успешно отправлена! Пожалуйста, ожидайте звонка от оператора колл-центра для подтверждения вашей записи"}
          </h2>
          {SpecialityId === 34 ||
          SpecialityId === 40 ||
          SpecialityId === 17 ? (
            <a
              href="https://medlabexpress.perm.ru/ru/perm/uzi/"
              className="online-record-modal__link"
            >
              Для проведения УЗИ требуется подготовка
            </a>
          ) : (
            <p className="online-record-modal__subtitle">
              Не забудьте взять паспорт и маску
            </p>
          )}
          <button className={"btn online-record-modal__btn"} onClick={onClose}>
            OK
          </button>
        </div>
      </Fade>
    </Modal>
  );
};

export default OnlineRecordModal;
