import { makeStyles, TextField } from '@material-ui/core';
import React, { useState } from 'react'

const useStyles = makeStyles((theme) => ({
    textField: {
      width: '100%',
    },
  }));

export function InputDateMask ({value, onChange, className, label, propertyName, validation,   ...props}) {
    const classes = useStyles();
    const [error, setError] = useState(false)
    const onBlur = (e) => {
      if (validation) {
        !e.target.value ? setError(true) : setError(false)
      }
    }

    return (
      <form style={{border: error ? '1px solid #e30613' : 'none'}} className={["input-form", className].join(" ")}>
        <TextField
            className={classes.textField}
          onBlur={onBlur}
          error={validation}
          id="filled-error-helper-text"
          helperText={(validation && error) ? 'Обязательное поле' : null}
          label={label}
          variant="outlined"
          name={propertyName}
          onChange={onChange}
          InputLabelProps={{
            shrink: true,
            }}
          {...props}
        />
      </form>
    );
  }