export const LOAD_DOCTORS_SUCCESS = "LOAD_DOCTORS_SUCCESS";
export const LOAD_SPECIALITIES_SUCCESS = "LOAD_SPECIALITIES_SUCCESS";
export const LOAD_SERVICES_SUCCESS = "LOAD_SERVICES_SUCCESS";
export const LOAD_SERVICES_BY_DOCTOR_SUCCESS =
  "LOAD_SERVICES_BY_DOCTOR_SUCCESS";
export const LOAD_FREE_APPOINTMENTS = "LOAD_FREE_APPOINTMENTS";
export const LOAD_FREE_APPOINTMENTS_SUCCESS = "LOAD_FREE_APPOINTMENTS_SUCCESS";
export const LOAD_FREE_APPOINTMENTS_FAIL = "LOAD_FREE_APPOINTMENTS_FAIL";
export const CREATE_APPOINTMENT_SUCCESS = "CREATE_APPOINTMENT_SUCCESS";
export const AUTH = "AUTH";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const DOCTOR_TABS = "DOCTOR_TABS";
export const LOAD_BUSY_DAYS_SUCCESS = "LOAD_BUSY_DAYS_SUCCESS";
