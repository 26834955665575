import FormControl from "@material-ui/core/FormControl";
import TextField from "@material-ui/core/TextField";
import React, { useState } from "react";

const InputForm = ({ propertyName, onChange, className, label, validation, ...props }) => {
  const [error, setError] = useState(false)
  const onBlur = (e) => {
    if (validation) {
      !e.target.value ? setError(true) : setError(false)
    }
  }
  return (
    <FormControl style={{border: error ? '1px solid #e30613' : 'none'}} className={["input-form", className].join(" ")}>
      <TextField
        onBlur={onBlur}
        label={label}
        error={validation}
        id="filled-error-helper-text"
        helperText={(validation && error) ? 'Обязательное поле' : null}
        variant="outlined"
        name={propertyName}
        onChange={onChange}
        {...props}
      />
    </FormControl>
  );
};

export default InputForm;
