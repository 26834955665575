import React from "react";
import Logotype from "../../../components/kit/Logo/Logotype";

const ChoiceServices = ({ choiceServiceClickNext, choiceServiceClickPrev }) => {
  return (
    <div className="choice-services">
      <div className="choice-services-main">
        <Logotype className={"choose-master-root-logo"} />
      </div>
      <div className="choice-services-links">
        <div className="choice-services-link" onClick={choiceServiceClickNext}>
          <p>ЗАПИСАТЬСЯ НА УЗИ</p>
        </div>
        {/* <div className="choice-services-link" onClick={choiceServiceClickPrev}>
          <p>Записаться на ПЦР</p>
        </div> */}
      </div>
    </div>
  );
};

export default ChoiceServices;
