import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Colors from "../../../global/styles/Colors";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import "./index.scss";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${Colors.NEUTRAL["border"]}`,
    borderRadius: "6px",
    color: Colors.NEUTRAL["black"],
  },
}));

const SelectInput = ({
  id,
  name,
  onChange,
  className,
  label,
  value,
  inputProps,
  children,
}) => {
  const classes = useStyles();
  const [error, setError] = useState(0)
  const onHandleChange = (e) => {
    onChange(e)
    setError(e.target.value)
  }
  return (
    <>
      <FormControl
        className={[classes.root, "select-form", className].join(" ")}
        style={{border: (error === -1) ? '1px solid red' : 'none'}}
      >
        <InputLabel variant="outlined" id={id + "InputLabel"}>
          {label}
        </InputLabel>
        <Select
          label={label}
          variant="outlined"
          className={className}
          name={name}
          labelId={id + "label"}
          id={id}
          value={value}
          onChange={onHandleChange}
          inputProps={inputProps}
        >
          {children}
        </Select>
        {error === -1 ? <FormHelperText id="my-helper-text">Это поле обязательное</FormHelperText> : null}
      </FormControl>
    </>
  );
};

export default SelectInput;
