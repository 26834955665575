import React from "react";
import { logoUrl, logoBG } from "../../../constants.json";
const Logotype = ({ width, height, className }) => {
  return (
    <a href="https://medlabexpress.perm.ru/">
    <img
      src={logoUrl}
      alt="logotype"
      width={width}
      height={height}
      className={className}
      style={{
        background: logoBG ? logoBG : "transparent",
        borderRadius: logoBG ? "15px" : "0",
      }}
    />
    </a>
  );
};

export default Logotype;
