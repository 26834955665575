import React from "react";
import { currency_symbol } from "./../../../../constants.json";

const SelectFormOption = ({
  item,
  onSelect,
  isChecked,
  onSelectSpecialityId,
  nextPage,
  onSelectServiceId
}) => {
  const func = () => {
    onSelect(item);
    if (onSelectSpecialityId) {
      onSelectSpecialityId(item.SpecialityId);
      onSelectServiceId(item.Id);
      nextPage();
    }
  };
  return (
    <div className="select-service-root__option" onClick={func}>
      {nextPage || (
        <div
          className={`select-service-root__checkbox ${
            isChecked ? "checked" : ""
          }`}
        ></div>
      )}
      <div className="select-service-root__divide">
        <div className="d-flex flex-column justify-content-center">
          <p className="select-service-root__name">{item.Name}</p>
          <p className="select-service-root__amount">
            {item.Price === 0
              ? "Бесплатно"
              : `${item.Price} ${currency_symbol}`}
          </p>
        </div>
        {onSelectSpecialityId ? null : (
          <div className="select-service-root__time">
            <p>{item.TimeMin}</p>
            <p>мин</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SelectFormOption;
