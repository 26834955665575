import PropTypes from "prop-types";
import React from "react";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import { connect } from "react-redux";
import SearchIcon from "../../../components/kit/Icons/SearchIcon";
import Logotype from "../../../components/kit/Logo/Logotype";
import * as doctorActions from "../../../redux/actions/doctorActions";
import * as specialityActions from "../../../redux/actions/specialityActions";
import DoctorTab from "../../DoctorTab";
import DoctorList from "../doctors/DoctorList";
import SimpleAlert from "./Alert/SimpleAlert";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import { logoWidth } from "../../../constants.json";
import getExludedService from "../../../api/excludedServiceApi";


class ChooseMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      inputSearch: "",
      isLoading: true,
      filteredDoctors: []
    };
  }

  componentDidMount() {
    this.props
      .loadSpecialities()
      .then(() => {
        // this.setState({ isLoading: false });
        this.setState({ errorMessage: "" });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Ошибка загрузки специальностей: " + error,
        });
      });

    this.props
      .loadDoctors()
      .then(() => {
        this.setState({ errorMessage: "" });
      })
      .catch((error) => {
        this.setState({ errorMessage: "Ошибка загрузки врачей: " + error });
      });
      getExludedService(this.props.selectedService)
        .then(arrDoctors => {

          const filteredDoctorsBySpeciality = this.props.doctors.filter(
            (doctor) => doctor.IdSpeciality === this.props.selectedSpecialityId || doctor.AddSpecialities.some(AddSpeciality => AddSpeciality.Id === this.props.selectedSpecialityId)
          )
          const filteredDoctorsByService = filteredDoctorsBySpeciality.filter(doctor => {
            return !arrDoctors.some(arrDoc => arrDoc.IdDoctor === doctor.Id)
          })

          this.setState({ 
            isLoading: false,
            filteredDoctors: arrDoctors.lenght ?  filteredDoctorsBySpeciality : filteredDoctorsByService,
          });
        })
  }

  componentWillReceiveProps(props) {
    this.setState({ errorMessage: props.errorMessage });
  }


  render() {
    const punktsFilter = this.props.doctors.filter(
      (doctor) => doctor.IdSpeciality === 41 || doctor.IdSpeciality === 42
    );

    const onEmpty = () => {
      this.props.handleClickPrev()
      if (this.props.handleEmptyService) {
        this.props.handleEmptyService()
      }
      
    }

    return (
      <>
        {this.state.errorMessage && (
          <SimpleAlert
            severity="error"
            errorMessage={this.state.errorMessage}
          />
        )}
        <div className="choose-master-root">
          {!this.props.AllSevices && (
            <div className="choose-master-root__title">
              <h3 className="title">
                Выберите пункт для записи
                <p>
                  Если Вас не устраивает время онлайн записи, позвоните нам - мы
                  обязательно Вам поможем
                </p>
              </h3>
              <Logotype
                className={"choose-master-root-logo"}
                width={logoWidth}
              />
            </div>
          )}

          <label className="choose-master-root__search">
            <SearchIcon
              width="18"
              height="18"
              className="choose-master-root__search-icon"
            />
            <input
              onChange={(e) => {
                this.setState({
                  inputSearch: e.target.value.toLocaleLowerCase(),
                });
              }}
              type="text"
              placeholder="Поиск"
            />
          </label>
          {!this.props.AllSevices && (
            <DoctorTab
              doctors={this.props.doctors}
              selectedSpeciality={this.state.selectedSpeciality}
              specialities={this.props.specialities}
              punkts={true}
            />
          )}

          {this.state.isLoading ? (
            <LoadingComponent />
          ) : (
            <div className="choose-master-root__list">
              <DoctorList
                doctors={
                  !this.props.AllSevices ? punktsFilter : this.state.filteredDoctors
                }
                onSelectMaster={this.props.onSelectMaster}
                inputSearch={this.state.inputSearch}
              />
            </div>
          )}
        </div>
        <div className="d-flex mt-30">
          <GeneralButton
            className="mr-10"
            title="Назад"
            onClick={onEmpty}
          />
        </div>
      </>
    );
  }
}

ChooseMaster.propTypes = {
  specialities: PropTypes.array.isRequired,
  doctors: PropTypes.array.isRequired,
  loadSpecialities: PropTypes.func.isRequired,
  loadDoctors: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
  return {
    specialities: state.specialities.map((speciality) => {
      return {
        ...speciality,
        doctors: state.doctors.filter((doctor) => {
          let allSpecialities = [doctor.SpecialityName].concat(
            doctor.AddSpecialities
          );
          return allSpecialities.find((el) => el === speciality.Name);
        }),
      };
    }),
    doctors: state.doctors.map((doctor) => {
      return {
        ...doctor,
        specialities: state.specialities.filter(
          (speciality) => speciality.Id === doctor.IdSpeciality
        ),
      };
    }),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadSpecialities: (speciality) =>
      dispatch(specialityActions.loadSpecialities()),
    loadDoctors: (doctor) => dispatch(doctorActions.loadDoctors()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChooseMaster);
