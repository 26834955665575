import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import GeneralButton from "../../../components/kit/GeneralButton/GeneralButton";
import SelectService from "./SelectService/SelectService.jsx";
import * as serviceActions from "../../../redux/actions/serviceActions";
import SimpleAlert from "./Alert/SimpleAlert";
import LoadingComponent from "./LoadingComponent/LoadingComponent";
import SelectAllService from "./SelectService/SelectAllService";
import DoctorTab from "../../DoctorTab";
import _ from "lodash";
import Logotype from "../../../components/kit/Logo/Logotype";
import { logoWidth } from "../../../constants.json";
import SearchIcon from "../../../components/kit/Icons/SearchIcon";

class AllServices extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      isLoading: true,
      inputSearch: ''
    };
  }

  componentDidMount() {
    this.props
      .loadServices()
      .then((services) => {
        this.setState({ isLoading: false });
        this.setState({ errorMessage: "" });
      })
      .catch((error) => {
        this.setState({
          errorMessage: "Ошибка загрузки услуг по врачу: " + error,
        });
      });
  }

  componentWillReceiveProps(props) {
    this.setState({ errorMessage: props.errorMessage });
  }

  onSelectServiceVariant = (id) => {
    const services = this.props.selectedOptions;
    if (services.includes(id)) {
      const index = services.indexOf(id);
      services.splice(index, 1);
    } else {
      services.push(id);
    }
    this.setState({ services });
  };

  render() {
    const disabled = this.props.selectedOptions < 1;
    const doctorsServices = this.props.services.filter(
      (service) => service.SpecialityId !== 41 && service.SpecialityId !== 42
    );
    return (
      <>
        {this.state.isLoading ? (
          <LoadingComponent errorMessage={this.state.errorMessage} />
        ) : (
          <>
            {this.state.errorMessage && (
              <SimpleAlert
                severity="error"
                errorMessage={this.state.errorMessage}
              />
            )}
            <div className="choose-master-root__title">
              <h3 className="title">
                Выберите услугу для записи
                <p>
                  Если Вас не устраивает время онлайн записи, позвоните нам - мы
                  обязательно Вам поможем
                </p>
              </h3>
              <Logotype
                className={"choose-master-root-logo"}
                width={logoWidth}
              />
            </div>

            <label className="choose-master-root__search">
              <SearchIcon
                width="18"
                height="18"
                className="choose-master-root__search-icon"
              />
              <input
                onChange={(e) => {
                  this.setState({
                    inputSearch: e.target.value.toLocaleLowerCase(),
                  });
                }}
                type="text"
                placeholder="Поиск"
              />
            </label>

            <DoctorTab
              doctors={this.props.doctors}
              selectedSpeciality={this.state.selectedSpeciality}
              specialities={this.props.specialities}
            />
            <div className="service-variant-root" style={{padding: 0}}>
              {doctorsServices ? 
              (<SelectAllService
                doctors={this.props.doctors}
                onSelectSpecialityId={this.props.onSelectSpecialityId}
                onSelectServiceId={this.props.onSelectServiceId}
                onSelect={this.onSelectServiceVariant}
                categories={this.props.doctorSpecialities}
                services={doctorsServices}
                selectedOptions={this.props.selectedOptions}
                selectedSpecialityId={this.props.selectedSpecialityId}
                nextPage={this.props.handleClickNext}
                inputSearch={this.state.inputSearch}
              />) : 'Ошибка при загрузке услуг'}
              
              <div className="d-flex mt-30">
                <GeneralButton
                  className="mr-10"
                  title="Назад"
                  onClick={this.props.mainPageLinck}
                />
              </div>
            </div>
          </>
        )}
      </>
    );
  }
}
AllServices.propTypes = {
  services: PropTypes.array,
  loadServices: PropTypes.func.isRequired,
};

// eslint-disable-next-line
Array.prototype.distinct = function (item) {
  var results = [];
  for (var i = 0, l = this.length; i < l; i++)
    if (!item) {
      if (results.indexOf(this[i]) === -1) results.push(this[i]);
    } else {
      if (results.indexOf(this[i][item]) === -1) results.push(this[i][item]);
    }
  return results;
};

function mapStateToProps(state) {
  return {
    services: state.services,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loadServices: (service) => dispatch(serviceActions.loadServices(service)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AllServices);
