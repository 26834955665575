import { api, handleResponse, handleError } from "./apiUtils";
import { GET_EXCLUDED_SERVICE } from "../global/Constants/excludedService";
import { getPhotos, getNearestFreeTime } from "./../constants.json";

export default function getExludedService(serviceId) {
  return api
    .get(`${GET_EXCLUDED_SERVICE}`, {
      params: {
        doctorId: 0,
        serviceId,
      },
    })
    .then(handleResponse)
    .catch(handleError);
}

