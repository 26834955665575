import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { setDoctorTabs } from '../redux/actions/doctorTabsAction'
import _ from 'lodash'
import { useHorizontalScroll } from '../global/hooks/useHorizontalScroll'


const DoctorTab = ({ doctors, specialities, punkts }) => {
  const dispatch = useDispatch()
  const scrollRef = useHorizontalScroll()
  if (punkts) {
	  setTimeout(() => {
		const urlParams = new URLSearchParams(window.location.search)
		const urlSpecialityId = parseInt(
		  urlParams.get('specialityId') &&
			urlParams.get('specialityId').replace(/"/g, '')
		)
		const urlSpeciality = specialities.find((speciality) => {
		  return speciality.Id === urlSpecialityId
		})
		urlSpeciality && dispatch(setDoctorTabs(urlSpeciality.Name))
	  }, 400)
  }
  const setDoctorTabHandler = (e) => {
	if (!e.target.value) {
		dispatch(setDoctorTabs('все'))
	} else {
		dispatch(setDoctorTabs(e.target.value.toLowerCase()))
	}
  }

  const doctorTab = useSelector(
    (s) => s.doctorTabsReducer.doctorTab
  ).toLowerCase()

  const filtredSpecialities = _.uniqWith(
    doctors.reduce((acc, rec) => {
      return [
        ...acc,
        {
          SpecialityName: rec.SpecialityName,
          IdSpeciality: rec.IdSpeciality,
        },
      ]
    }, []),
    _.isEqual
  ).filter(
	(doctor) => {
		if (punkts) {
			return doctor.IdSpeciality === 41 || doctor.IdSpeciality === 42
		} else {
			return  doctor.IdSpeciality !== 41 && doctor.IdSpeciality !== 42
		}
	}
  )

	useEffect(() => {

	return () => {
		dispatch(setDoctorTabs('все'))
	}

	}, [dispatch])
	

  return (
		<div
			className='choose-master-root__tabs'
			ref={scrollRef}
		>
			<Link to={'/'}>
				<button
					className={`${
						doctorTab === 'все' ? 'active' : ''
					} btn choose-master-root__tab`}
					onClick={setDoctorTabHandler}
				>
					Все
				</button>
			</Link>
			{filtredSpecialities.map((doctor, idx) => {
				return (
					<Link to={`/`} key={idx}>
						<button
							className={`${
								doctorTab === doctor.SpecialityName.toLowerCase()
									? 'active'
									: ''
							} btn choose-master-root__tab`}
							onClick={setDoctorTabHandler}
							value={doctor.SpecialityName}
							id={doctor.IdSpeciality}
						>
							{doctor.SpecialityName}
						</button>
					</Link>
				)
			})}
		</div>
	)
}

export default DoctorTab
