import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import { makeStyles } from "@material-ui/core/styles";
import InputMask from "react-input-mask";
import FormControl from "@material-ui/core/FormControl";
import Colors from "../../../global/styles/Colors";
import { phoneMask } from "../../../constants.json";
import { FormHelperText } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "1px solid #b6b5b5", // TODO need to make color like figma
    borderRadius: "6px",
    color: Colors.NEUTRAL["black"],
    padding: "11px 16px",
    position: "relative",
  },
  placeHolder: {
    position: "absolute",
    top: "18px",
    left: "16px",
    color: Colors.NEUTRAL["black"],
  },
}));

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;
  return (
    <InputMask
      mask={phoneMask}
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
    />
  );
}

const InputPhoneMask = ({ value, propertyName, onChange, className, validation }) => {
  const [isActive, setValue] = useState(true);
  const classes = useStyles();
  const [error, setError] = useState(false)

  return (
    <FormControl style={{border: error ? '1px solid #e30613' : 'none'}} className={[classes.root, className].join(" ")}>
      <Input
        value={value}
        name={propertyName}
        onChange={onChange}
        isActive={isActive}
        onFocus={() => setValue((prevValue) => !prevValue)}
        onBlur={(e) => {
          setValue((prevValue) => !prevValue)
          if (validation) {
            value.includes('_') ? setError(true) : setError(false)
          }
        }}
        inputComponent={TextMaskCustom}
        placeholder={"Номер телефона"}
        disableUnderline
      />
      {error ? <FormHelperText id="my-helper-text">Это поле обязательное</FormHelperText> : null}
    </FormControl>
  );
};

export default InputPhoneMask;
