import PropTypes from "prop-types";
import React, { useEffect } from "react";
import SelectServiceOption from "./SelectServiceOption";
import "./selectService.scss";
import _ from "lodash";
import { useSelector } from "react-redux";
import { useState } from "react";

const SelectAllService = ({
  onSelect,
  services,
  selectedOptions,
  onSelectSpecialityId,
  nextPage,
  inputSearch,
  doctors,
  onSelectServiceId
}) => {
  const selectedSpeciality = useSelector(
    (s) => s.doctorTabsReducer.doctorTab
  ).toLowerCase();

  const [filteredServices, setFilteredServices] = useState(services)

  useEffect(() => {
   
    if (selectedSpeciality === 'все') {
      setFilteredServices(services)
      if (inputSearch) {
        setFilteredServices(prev => prev.filter(service => service.Name.toLowerCase().includes(inputSearch)))
      }
    } else {
      setFilteredServices(services.filter(service => service.SpecialityName.toLowerCase() === selectedSpeciality))
      if (inputSearch) {
        setFilteredServices(prev => prev.filter(service => service.Name.toLowerCase().includes(inputSearch)))
      }
    }

  }, [services, selectedSpeciality, inputSearch])

  return (
    <div className="select-service-root">
      {filteredServices.length ? filteredServices.map((el, idx) => (
        <SelectServiceOption
          nextPage={nextPage}
          onSelectServiceId={onSelectServiceId}
          onSelectSpecialityId={onSelectSpecialityId}
          onSelect={onSelect}
          item={el}
          key={idx}
          isChecked={selectedOptions.some((it) => it.Id === el.Id)}
        />
      )) : 'Ничего не найдено'}
    </div>
  );
};

SelectAllService.propTypes = {
  categories: PropTypes.array.isRequired,
  services: PropTypes.array.isRequired,
};

export default SelectAllService;
